export const compareDates = (a: Date, b: Date): boolean =>
	a.getTime() > b.getTime()

export const getCanonicalUrl = (
	host: string,
	pageLocale: string,
	pagePath: string,
	pageCanonicalUrl: string
) => {
	if (pageCanonicalUrl) return pageCanonicalUrl

	if (pagePath === '/') return `https://${host}/${pageLocale}`

	const pageUrlWithParams = `https://${host}/${pageLocale}${pagePath}`
	const pageUrl = pageUrlWithParams.split('?')[0]

	if (pageUrlWithParams.includes('page=')) {
		const url = new URL(pageUrlWithParams)
		const searchParams = new URLSearchParams(url.search)
		const pageNumber = searchParams.get('page')

		if (pageNumber === '1') return pageUrl

		return `${pageUrl}?page=${pageNumber}`
	}

	return pageUrl
}

export const validHrefLangs = (pageHrefLangs: string): boolean => {
	// prevent XSS
	const BAD_WORDS = ['script', 'iframe']

	for (const word of BAD_WORDS) {
		if (pageHrefLangs.includes(word)) return false
	}

	// otherwise proceed
	const hrefLangs = pageHrefLangs.split('/>').slice(0, -1)
	for (let i = 0; i < hrefLangs.length; i += 1) {
		const hreflangWithoutWhiteSpaces = hrefLangs[i]?.replace(/\s/g, '')
		// removed white spaces (since white spaces doesn't create an issue), and checking for a particular pattern of attributes that we want inside <link> tag
		if (hreflangWithoutWhiteSpaces) {
			if (
				hreflangWithoutWhiteSpaces.match(/</g)?.length !== 1 ||
				!/<linkrel=("[^"]*"|'[^']*')href=("[^"]*"|'[^']*')hreflang=("[^"]*"|'[^']*')+/.test(
					hreflangWithoutWhiteSpaces
				)
			) {
				return false
			}
		}
	}
	return true
}

export const minutesDiff = (dateTimeValue2: Date, dateTimeValue1: Date) => {
	let differenceValue =
		(dateTimeValue2.getTime() - dateTimeValue1.getTime()) / 1000
	differenceValue /= 60
	return Math.abs(Math.round(differenceValue))
}

export const insertAsterisk = (htmlString: string, required: boolean) => {
	if (required) {
		const pattern = /(<\/\w+>)(?![\s\S]*<\/\w+>)/g // regex pattern to find the last closing tag inside the HTML string
		return htmlString.replace(
			pattern,
			`<span class="gigya-required-display gigya-reset" style="color:#ce4635;padding: 0 5px;">*</span>$1`
		)
	}
	return htmlString
}

export const isServer = () => typeof window === 'undefined'
